import React, {
  ReactElement,
  useEffect,
  useRef,
  memo,
  useCallback,
  useContext,
} from "react";
import styles from "./VideoViewer.module.css";
import { ContentSizeType } from "../../../../types/content";
import { VideoFile } from "../../../../store/files/types";
import { useSelector } from "react-redux";
import { PlayerState } from "../../../../store/rootReducer";
import { VideoPlayer as OldVideoPlayer } from "@screencloud/signage-player-components";
import { ScreenState } from "../../../../store/screen/types";
import { Logger } from "../../../../logger/logger";
import { isProduction } from "../../../../utils/devUtils";
import { SimpleVideoViewer } from "./SimpleVideoViewer";
import { ShakaVideoViewer } from "./ShakaVideoViewer";
import { getMutedStatus, isHlsVideo } from "./utils";
import { AudioSettings } from "../../../../providers/AudioSettingsProvider/types";
import { AudioSettingsContext } from "../../../../providers/AudioSettingsProvider/AudioSettingsProvider";
import ClientApiContext from "../../../../ClientApiContext";
import { HLSVideoViewer } from "./HLSVideoViewer";
const log = new Logger("videoViewer");
const e2eTestIdentifier = "video-viewer";

interface SimpleVideoProps {
  src: string;
  isPreload: boolean;
  hasNextItem?: boolean;
  muted: boolean;
  controls: boolean;
  loop: boolean;
  sizeStyle: string;
  playbackPositionMs: number;
  sizeType?: ContentSizeType;
}

const SimpleVideo = memo(
  (props: SimpleVideoProps): ReactElement<SimpleVideoProps> => {
    const videoElementRef = useRef<OldVideoPlayer | null>(null);

    const clientApiContext = useContext(ClientApiContext);
    const isTizenPlayer = clientApiContext?.playbackConfig?.isTizenPlayer;
    const {
      src,
      isPreload,
      muted,
      loop,
      controls,
      sizeType,
      playbackPositionMs,
    } = props;

    // tizen doesn't play well with seek playback position. for a short video then skip seek if playback position less than 1sec.
    const finalPlaybackPositionMS =
      isTizenPlayer && playbackPositionMs < 1000
        ? undefined
        : playbackPositionMs;

    useEffect(() => {
      if (!isPreload && src !== "") {
        try {
          videoElementRef.current?.play();
        } catch (error) {
          log.warn({
            message: `Unable to play ${src}:`,
            context: {
              error: JSON.stringify(error),
            },
            proofOfPlayFlag: true,
          });
        }
      }
    }, [isPreload, src]);

    const screen = useSelector<PlayerState, ScreenState>(
      (state) => state.screen
    );

    const onError = useCallback(
      (event?: unknown) => {
        const errorEvent = event as
          | { target: { error?: MediaError } }
          | undefined;

        const error = errorEvent?.target?.error;

        if (error) {
          log.warn({
            message: `Cannot load or play video ${src}, error code ${error?.code}`,
            context: {
              errorMessage: error?.message,
              errorCode: error?.code,
              screenId: screen.id,
            },
            proofOfPlayFlag: true,
          });
        } else {
          log.warn({
            message: `Cannot load or play video ${src}: ${errorEvent}`,
            context: {
              errorMessage: errorEvent?.target?.error?.message,
              screenId: screen.id,
              errorCode: errorEvent?.target?.error?.code,
            },
            proofOfPlayFlag: true,
          });
        }
      },
      [src, screen.id]
    );

    return (
      <div
        className={`${e2eTestIdentifier} ${styles.persiePlayer}`}
        id={e2eTestIdentifier}
        aria-label={e2eTestIdentifier}
        data-testid="simple-video"
      >
        <OldVideoPlayer
          ref={videoElementRef}
          src={src}
          autoPlay={false}
          loop={loop}
          muted={screen.isMuted || muted}
          preload="auto"
          controls={controls}
          // sizingType={sizeStyle as ContentSizeType} - Switch this back over to class name when we use the new video player again
          sizingType={sizeType}
          initialPlaybackPositionMs={finalPlaybackPositionMS}
          onError={onError}
        />
      </div>
    );
  }
);
SimpleVideo.displayName = "SimpleVideo";

interface VideoViewerProps {
  src: string;
  file: VideoFile;
  isPreload: boolean;
  hasNextItem?: boolean;
  initialPlaybackPositionMs: number; // where should the video start it's playback
  userInteractionEnabled: boolean;
  loop?: boolean;
  sizeType?: ContentSizeType;
  shouldUsePersistentVideo?: boolean;
}
export const VideoViewer = memo(
  (props: VideoViewerProps): ReactElement<VideoViewerProps> => {
    const {
      isPreload,
      hasNextItem,
      src,
      sizeType,
      initialPlaybackPositionMs,
      loop = true, // default is true since we want the video to loop until timeline decides to unmount the component
      userInteractionEnabled,
      shouldUsePersistentVideo,
    } = props;
    const screen = useSelector<PlayerState, ScreenState>(
      (state) => state.screen
    );
    const audioSettings = useContext<AudioSettings>(AudioSettingsContext);
    const muted =
      audioSettings.shouldMuteMedia || getMutedStatus(screen.isMuted || false);
    const controls = userInteractionEnabled && muted;

    const sizeStyle = sizeType === "fill" ? styles.videoFill : styles.videoFit;

    useEffect(() => {
      log.info({
        message: `Show Video ${props.file.name}`,
        context: {
          id: props.file.id,
          name: props.file.name,
          contentType: props.file.mimetype,
          isPreload: props?.isPreload,
          src: src,
          thumbnail: props.file.thumbnail,
        },
      });
    }, [
      props.file.id,
      props.isPreload,
      props.file.mimetype,
      props.file.urlKey,
      props.file.name,
      props.file.thumbnail,
      src,
    ]);

    if (!src) return <></>;

    if (shouldUsePersistentVideo) {
      return (
        <div
          className={`${e2eTestIdentifier} ${styles.persiePlayer}`}
          id={e2eTestIdentifier}
          aria-label={e2eTestIdentifier}
          data-testid="shaka-video"
        >
          <ShakaVideoViewer
            isPreload={isPreload}
            hasNextItem={hasNextItem}
            src={src}
            muted={muted}
            controls={controls}
            loop={loop}
            sizeType={sizeType}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
            mimeType={props.file.mimetype}
            filename={props.file.name}
          />
        </div>
      );
    }

    if (!isProduction() && !isHlsVideo(src)) {
      return (
        <div
          className={`${e2eTestIdentifier} ${styles.persiePlayer}`}
          id={e2eTestIdentifier}
          aria-label={e2eTestIdentifier}
          data-testid="simple-video"
        >
          <SimpleVideoViewer
            src={src}
            isPreload={isPreload}
            hasNextItem={hasNextItem}
            muted={muted}
            controls={controls}
            loop={loop}
            sizeType={sizeType}
            initialPlaybackPositionMs={initialPlaybackPositionMs}
          />
        </div>
      );
    }

    if (isHlsVideo(src)) {
      return (
        <HLSVideoViewer
          src={src}
          isPreload={isPreload}
          hasNextItem={hasNextItem}
          muted={muted}
          controls={controls}
          loop={loop}
          playbackPositionMs={initialPlaybackPositionMs}
          sizeType={sizeType}
        />
      );
    }

    return (
      <SimpleVideo
        src={src}
        isPreload={isPreload}
        hasNextItem={hasNextItem}
        muted={muted}
        controls={controls}
        loop={loop}
        sizeStyle={sizeStyle}
        sizeType={sizeType}
        playbackPositionMs={initialPlaybackPositionMs}
      />
    );
  }
);
VideoViewer.displayName = "VideoViewer";
